<template>
  <div class="aw-list">
    <div class="aw-list__detail">
      <div class="aw-list__detail--img">
        <img :src="awardTypeLogo" :alt="awardType" />
      </div>
      <div class="aw-list__detail--content">
        <div class="aw-list__detail--content__top">
          <h5>{{ awardTypeTitle }}</h5>
          <div class="aw-list__detail--content__top--cta">
            <div
              class="aw-list__detail--content__top--cta__btn"
              @click="onPreview"
            >
              <img
                src="@/assets/images/awards/list/preview.svg"
                alt="preview"
              />
              <span>Preview</span>
            </div>
            <div
              class="aw-list__detail--content__top--cta__btn"
              @click="onEdit"
            >
              <img src="@/assets/images/awards/list/edit.svg" alt="edit" />
              <span>Edit</span>
            </div>
          </div>
        </div>
        <div class="aw-list__detail--content__bottom">
          <ul>
            <li v-if="awardType === 'course_completion_award'">
              Learner successfully completes all mandatory learning activities,
              including self-reflection questions, and reaches the end of the
              course.
            </li>
            <li v-else-if="awardType === 'course_participation_confirmation'">
              Learner successfully completes all mandatory learning activities,
              including self-reflection questions, and reaches the end of the
              course.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Users />
    <LxpModal
      :show.sync="showModal"
      id="award-preview-modal"
      centered
      title="Award Preview"
    >
      <AwardPreview
        :courseTitle="getCourseInfo?.display_name"
        :awardTitle="awardTypeTitle"
        :award="getAward"
        :infoArr="moreInformation"
        :showTitle="false"
        userName="Rebeca Smith"
        v-if="getAward"
      />
      <template #footer>
        <div class="aw-modal__footer">
          <LxpButton variant="primary" @click="showModal = false"
            >Close</LxpButton
          >
        </div>
      </template>
    </LxpModal>
  </div>
</template>
<script>
import { LxpButton, LxpModal, AwardPreview } from "didactica";
import Users from "./List/Users.vue";
import { mapGetters } from "vuex";
export default {
  components: { LxpButton, LxpModal, Users, AwardPreview },
  props: {
    award: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showModal: false
    };
  },
  computed: {
    ...mapGetters(["getAward", "getCourseInfo"]),
    awardType() {
      return this.award.content_type.slug;
    },
    awardTypeLogo() {
      let logo;
      if (this.awardType === "course_completion_award") {
        logo = require("@/assets/images/awards/list/award-of-completion.svg");
      } else if (this.awardType === "course_participation_confirmation") {
        logo = require("@/assets/images/awards/list/confirmation-of-participation.svg");
      }
      return logo;
    },
    awardTypeTitle() {
      let text;
      if (this.awardType === "course_completion_award") {
        text = "Award Of Completion";
      } else if (this.awardType === "course_participation_confirmation") {
        text = "Confirmation of Participation";
      }
      return text;
    },
    moreInformation() {
      return [
        { title: "Admission criteria", key: "admission_criteria" },
        { title: "Course description", key: "course_description" },
        { title: "Course activities", key: "course_activity" },
        { title: "Course outcomes", key: "course_outcome" },
        { title: "Assessment", key: "course_assessments" },
        { title: "Other information", key: "other_information" }
      ];
    }
  },
  methods: {
    onEdit() {
      let type = this.awardType.replaceAll("_", "-");
      this.$router.push({ path: `awards-form/${type}/edit` });
    },
    onPreview() {
      this.showModal = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.aw-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .aw-list__detail {
    @include horizontal-space-between;
    gap: 16px;
    padding: 16px;
    border-radius: 16px;
    background: $brand-neutral-0;
    box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
    .aw-list__detail--img {
      border-radius: 8px;
      padding: 8px;
      background: linear-gradient(283deg, #e7edfe 11.14%, #fff 112.61%);
    }
    .aw-list__detail--content {
      .aw-list__detail--content__top {
        @include horizontal-space-between;
        gap: 10px;
        h5 {
          @include subtitle-regular;
          color: $brand-neutral-900;
          flex: 1;
          margin-bottom: 0;
        }
        .aw-list__detail--content__top--cta {
          @include horizontal-space-between;
          gap: 10px;
          .aw-list__detail--content__top--cta__btn {
            display: flex;
            padding: 8px;
            gap: 8px;
            cursor: pointer;
            span {
              @include body-regular(500, $brand-primary);
            }
          }
        }
      }
      .aw-list__detail--content__bottom {
        margin-top: 16px;
        li {
          @include body-regular;
        }
      }
    }
  }
}
</style>
