<template>
  <div class="upw-list">
    <h3>Awards issued</h3>
    <div class="upw-list__heading">
      <div class="upw-list__content">
        <div class="upw-list__content--top" v-if="!showEmptyState">
          <div class="upw-list__content--heading">
            <search-bar
              placeholder="Search by name, ID or admin email..."
              @search="onSearch"
            />
            <filters :menu="menu" @filterChange="setFilterObj" />
          </div>
          <p class="cw-main__search-result" v-if="search !== ''">
            Showing results for <span>{{ search }}</span>
          </p>
          <p class="cw-main__count" v-if="count">{{ count }} results found</p>
        </div>
        <div class="upw-list__main">
          <LxpPaginationWrapper
            ref="userlistwrapper"
            :dataFetchURL="dataFetchURL"
            :authToken="`Bearer ${$keycloak.token}`"
            nextPageLabel="next"
            :pageSizeValue="20"
            showNumberPagination
            @count="updateCount"
          >
            <template v-slot:emptyState>
              <div class="cw-main__empty">
                <div class="cw-main__results--empty">
                  <img
                    :src="
                      hasSearchOrFilter
                        ? require('@/assets/images/awards/empty/search-result-empty.svg')
                        : require('@/assets/images/awards/list/empty-state.svg')
                    "
                    alt="empty"
                    class="cw-main__empty--img"
                  />
                  <list-empty-state
                    :search="search"
                    :hasFilters="showFilterPills"
                    v-if="hasSearchOrFilter"
                  />
                  <div class="cw-main__results--empty-div" v-else>
                    <p>No information available yet..</p>
                    <span
                      >Currently, there are no recent awards issued, and thus no
                      learner details are available at this time. Please check
                      back later for updates.
                    </span>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:data="slotProps">
              <LxpTable
                :tableData="getUserData(slotProps.data)"
                :tableHeader="tableHeader"
                :hasPagination="false"
                variant="primary"
                @onItemClick="onItemClick"
                @sort="onSort"
                v-if="slotProps.data.length > 0"
              >
              </LxpTable>
            </template>
          </LxpPaginationWrapper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { LxpTable, LxpPaginationWrapper } from "didactica";
// components
import SearchBar from "./SearchBar.vue";
import ListEmptyState from "./ListEmptyState.vue";
import Filters from "./Filters.vue";
export default {
  components: {
    LxpTable,
    LxpPaginationWrapper,
    SearchBar,
    ListEmptyState,
    Filters
  },
  data() {
    return {
      tableHeader: [
        {
          key: "name",
          name: "Name",
          thStyle: `width: 25%`,
          sortable: false
        },
        {
          key: "email",
          name: `Email`,
          thStyle: `width: 35%`,
          sortable: false
        },
        {
          key: "status",
          name: "Status",
          tdStyle: `text-transform: capitalize`,
          sortable: true
        },
        {
          key: "date",
          name: "Date",
          sortable: false
        }
      ],
      showAddUser: false,
      search: "",
      count: null,
      filter: {
        date: "",
        from_date: "",
        to_date: ""
      },
      ordering: "",
      menu: [
        {
          heading: "Type",
          id: "date",
          selected: "",
          collapse: false,
          items: [
            { value: "1-months", text: "last month" },
            { value: "3-months", text: "last 3 months" },
            { value: "6-months", text: "last 6 months" },
            { value: "1-years", text: "last year" },
            { value: "5-years", text: "last 5 years" },
            { value: "10-years", text: "last 10 years" }
          ],
          type: "radio"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["allConfig"]),
    dataFetchURL() {
      return `${this.allConfig.baseURL}/credentials/v1/courses/${this.$route.params.id}/users/?q=${this.search}&ordering=${this.statusFilter}&created__gte=${this.filter.from_date}&created__lte=${this.filter.to_date}`;
    },
    showFilterPills() {
      return this.filter.date !== "" || this.ordering !== "";
    },
    statusFilter() {
      return this.ordering ? this.ordering : "";
    },
    hasSearchOrFilter() {
      return this.search !== "" || this.showFilterPills;
    },
    showEmptyState() {
      return (
        this.count === 0 &&
        this.filter.date === "" &&
        this.ordering === "" &&
        this.search === "" &&
        !this.$refs["userlistwrapper"].isLoading
      );
    }
  },
  watch: {
    "filter.date"(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue !== "") {
          let value = newValue.value.split("-");
          this.filter.to_date = moment().format("YYYY-MM-DD");
          this.filter.from_date = moment()
            .subtract(value[0], value[1])
            .format("YYYY-MM-DD");
        } else {
          this.filter.from_date = "";
          this.filter.to_date = "";
        }
      }
    }
  },
  methods: {
    getUserData(data) {
      data.forEach(d => {
        let user = d.user;
        if (user.first_name !== "" || user.last_name !== "") {
          d.name = `${user.first_name} ${user.last_name}`;
        } else {
          d.name = "-";
        }
        user.email !== "" ? (d.email = user.email) : "-";
        if (d.created) {
          d.date = moment(d.created).format("DD/MM/YYYY");
        }
      });
      return data;
    },
    onItemClick(item) {
      if (item.key === "actions") {
        this.$store.commit("SET_CURRENT_USER", item.data);
        this.$router.push({
          path: `/partner/user-permissions/${item.data.id}/edit`
        });
      }
    },
    onAddUser() {
      this.showAddUser = true;
    },
    updateCount(c) {
      this.count = c;
    },
    setFilterObj() {
      this.menu.forEach(fm => {
        if (fm.selected !== "") {
          this.filter[fm.id] = fm.items.find(
            item => item.value === fm.selected
          );
        } else {
          this.filter[fm.id] = "";
        }
      });
    },
    onCreateNewUser() {
      this.$refs.userlistwrapper.reload();
    },
    onSort(item) {
      this.ordering = item.sort ? `-${item.key}` : item.key;
    },
    onSearch(search) {
      this.search = search;
    }
  }
};
</script>

<style lang="scss">
// empty state styles
@mixin empty-state {
  .cw-main__results--empty {
    @include center;
    min-height: 300px;
    text-align: center;
    .cw-main__results--empty-div {
      @include center;
    }
    .cw-main__empty--img {
      margin-bottom: 16px;
    }
    p {
      @include body-regular(500, $brand-neutral-900);
      margin-bottom: 0;
    }
    span {
      @include body-regular(400, $brand-neutral-900);
      margin: 0 21%;
    }
  }
}
.upw-list {
  border-radius: 16px;
  background: #fff;
  padding: 16px;
  box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  h3 {
    @include subtitle-regular;
    color: $brand-neutral-900;
    margin-bottom: 24px;
  }
  @include empty-state;
  .upw-list__heading {
    margin-bottom: 16px;
    .upw-list__content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .upw-list__content--top {
        display: flex;
        flex-direction: column;
        gap: 24px;
        .upw-list__content--heading {
          @include horizontal-space-between;
        }
        .cw-main__count {
          @include label-large(#919191);
          margin-bottom: 0;
        }
        .partner-search-bar {
          flex-grow: 1;
        }
      }
    }
  }
  .cw-main__search-result {
    @include subtitle-regular;
    color: $brand-neutral-900;
    span {
      color: $brand-secondary1-400;
      font-style: italic;
    }
  }
  .upw-list__main {
    .upw-list__edit {
      cursor: pointer;
      padding: 8px;
      background: $brand-secondary1-50;
      border-radius: 100px;
      display: inline-flex;
      visibility: hidden;
    }
    tr {
      &:hover {
        .upw-list__edit {
          visibility: visible;
        }
      }
    }
    .cw-main__empty {
      background: $brand-neutral-0;
      border-radius: 16px;
      border: 1px solid $brand-secondary1-50;
      width: 100%;
      .cw-main__empty--img {
        margin-bottom: 16px;
      }
      button {
        margin-top: 16px;
      }
    }
  }
}
</style>
