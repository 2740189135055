export default {
  mounted() {
    document.addEventListener("click", this.handleFilterClick);
  },
  methods: {
    handleFilterClick(e) {
      if (
        !(
          e.target.className.includes("upw-filter") ||
          e.target._prevClass === undefined ||
          e.target._prevClass.includes("custom-control")
        ) &&
        this.isFilterOpen
      ) {
        this.isFilterOpen = false;
      }
    }
  }
};
