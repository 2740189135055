<template>
  <div class="partner-search-bar">
    <b-input-group
      :class="{
        active: isFocused,
        'partner__search-clear': search !== ''
      }"
    >
      <b-input-group-prepend is-text>
        <img
          @click="onSearch"
          src="@/assets/images/awards/list/search-icon.svg"
          alt="search"
          width="24"
          height="24"
        />
      </b-input-group-prepend>
      <b-form-input
        :placeholder="placeholder"
        v-model.trim="search"
        @focus="onInputFocus"
        @blur="isFocused = false"
        @keyup.enter="onSearch"
        class="partner__search--input"
      ></b-form-input>
      <b-input-group-append is-text>
        <img
          src="https://files.lxp.academy.who.int/didactica/assets/images/modal/close.svg"
          alt="close"
          width="14"
          height="14"
          v-if="search !== ''"
          @click="onClear"
        />
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "Search"
    },
    searchURL: {
      type: String
      //required: true
    }
  },
  data() {
    return {
      search: "",
      isFocused: false,
      isSearchBarClicked: false
    };
  },
  methods: {
    onSearch() {
      this.$emit("search", this.search);
      this.onFocus();
    },
    onInputFocus() {
      this.isFocused = true;
    },
    onFocus() {
      this.$emit("onFocus");
    },
    onClear() {
      this.search = "";
      this.$emit("closeSearch");
      this.$emit("search", this.search);
    }
  }
};
</script>

<style lang="scss">
$border-radius-px: 100px;
@mixin active-searchbar {
  border: 1px solid $brand-primary-400;
  border-radius: $border-radius-px;
  box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  .input-group-text,
  .form-control {
    background: $brand-primary-100;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.partner-search-bar {
  position: relative;
  margin-right: 8px;
  flex: 1;
  .input-group {
    border: 1px solid $brand-primary-100;
    // box-shadow: 0px 4px 8px 4px rgb(33 33 33 / 10%);
    border-radius: 100px;
    z-index: 0;
    &.active,
    &:focus {
      @include active-searchbar;
      z-index: 1;
    }
    &:hover {
      background: $brand-primary-100;
      .input-group-text,
      .form-control {
        background: $brand-primary-100;
      }
    }
    .input-group-text {
      cursor: pointer;
      padding: 8px 8px 8px 16px;
    }
    .input-group-append {
      .input-group-text {
        padding: 8px 20px 8px 16px;
        border-radius: 0 100px 100px 0px;
      }
    }
    .input-group-text,
    .form-control {
      height: 40px;
      background-color: $brand-neutral-0;
      border: none;
      color: $brand-primary;
      border-radius: $border-radius-px;
    }
    .form-control {
      padding: 8px 20px 8px 0px;
      //   color: $ufl-search-color;

      &:focus {
        box-shadow: none;
      }
      &::placeholder {
        color: $brand-neutral-300;
      }
    }
  }
}
[dir="rtl"] {
  .input-group {
    &.partner__search-clear {
      margin-right: 1rem;
      .form-control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    .input-group-prepend {
      .input-group-text {
        border-radius: 0 100px 100px 0px;
        padding: 15px 20px 15px 15px;
      }
    }
    .input-group-append {
      .input-group-text {
        border-radius: 100px 0px 0px 100px;
        padding: 15px 15px 15px 20px;
      }
    }
    .form-control {
      padding: 15px 0px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
    }
  }
}
</style>
