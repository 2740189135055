<template>
  <div class="cw-main__results--empty-div">
    <p>
      Oops! It looks like we couldn't find any results for your
      {{ search !== "" ? "search" : "filters" }}.
    </p>
    <span>{{
      search !== ""
        ? "Please try again with different keywords or check your input for any typos."
        : "Your filter criteria didn't match any results. Please adjust your filters or try a different combination."
    }}</span>
  </div>
</template>

<script>
export default {
  props: {
    search: {
      type: String,
      required: true
    },
    hasFilters: {
      type: Boolean,
      required: true
    }
  }
};
</script>
